<template>
  <div class="content" v-if="orderInfo">
    <div class="charge-success-title">
      <img
        src="@/assets/img/pay/chenggong@2x.png"
        width="37"
        height="37"
        alt=""
      />
      {{ orderInfo.state === 2 ? "付款成功" : "付款失败" }}
    </div>
    <div class="qr-wrap wechat-public-hover">
      <div class="qr-content text-center">
        <img alt="二维码" class="qrcode" src="../../assets/img/qq-code.png" />
        <div class="qr-intro">
          <h4>订阅微信提醒</h4>
          <p>关注开罗尔官方公众号</p>
        </div>
      </div>
    </div>
    <div class="order-index-container container">
      <div class="text-center">
        <a class="btn order-action-half" :href="href">
          {{ orderInfo.state === 2 ? "去看课" : "去支付" }}</a
        >
      </div>
    </div>
    <section class="order-notice print-hide">
      <div class="container">
        <div class="live-order-title">直播须知</div>
        <ol style="padding-left: 15px">
          <li>
            电脑上推荐使用「搜狗高速浏览器」「360安全浏览器」「Chrome
            浏览器」进行访问，请不要使用低版本的 IE （IE8 以下）进行访问。
          </li>
          <li>
            手机端推荐下载我们的手机客户端「开罗尔在线」（在各大市场搜索即可下载安装）观看课程或使用手机自带浏览器进行访问。
          </li>
          <li>请认真浏览课程介绍，部分课程有教材或题目预习或准备的要求。</li>
          <li>
            报名直播课后已经开始直播及直播已经结束的课程恕不退款。未开始的直播课程可以退款，退款方法为：进入我的订单页面找到相应订单，点击退款按钮。
          </li>
          <li>在课程直播开始时间的半小时前即可进入直播页面进行准备。</li>
          <li>
            直播过程难免有部分卡顿现象，可以加我们的反馈QQ群 546189883
            进行反馈。
          </li>
        </ol>
      </div>
    </section>
  </div>
</template>
<script>
import api from "@/plugins/api";
export default {
  data() {
    return {
      orderInfo: null,
    };
  },
  mounted() {
    this.queryOrderState();
  },
  methods: {
    queryOrderState() {
      api
        .queryOrder({
          order_no: this.$route.params.orderNo,
          check_charged: 0,
        })
        .then(
          (res) => {
            if (res.data.code === 1) {
              this.orderInfo = res.data.data;
            } else {
              this.$message.warning(res.data.msg);
            }
          },
          () => {}
        );
    },
  },
  computed: {
    href() {
      let myCourseUrl = {
        开罗尔: "/my-course/course-list?course_type=0",
        质心官网: process.env.VUE_APP_ZHIXIN_OFFICIAL_URL + "/page/mineLives",
        质心灵动量: process.env.VUE_APP_ZHIXIN_LDL_URL + "/mycourse",
      };
      let urlOrigin =
        myCourseUrl[this.orderInfo.various[0].course_belong] ||
        "/my-course/course-list?course_type=0";
      return this.orderInfo.state === 2
        ? urlOrigin
        : `/pay/${this.orderInfo.order_no}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.charge-success-title {
  color: $purple-color;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  padding-top: 30px;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 37px;
    height: 37px;
    margin-right: 25px;
  }
}
.qr-wrap {
  border-radius: 4px;
  word-wrap: break-word;
  vertical-align: middle;
  max-width: 562px;
  padding: 32px 0;
  margin: 0 auto;
  border: 1px solid rgba(51, 51, 51, 0.1);
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;

  .qr-content {
    display: flex;
    align-items: center;
  }
  .qrcode {
    width: 124px;
    height: 124px;
  }
  .qr-intro {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    margin-left: 40px;
    h4 {
      font-size: 18px;
      font-weight: 500;
      color: #333;
      line-height: 25px;
      margin-top: 0;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 0;
    }
  }
}

.order-index-container {
  padding-top: 32px;
  padding-bottom: 64px;
  .order-action-half {
    background: $purple-color;
    color: white;
    width: 120px;
    height: 36px;
    font-size: 16px;
    padding: 0;
    line-height: 36px;
    font-weight: bold;
  }
}
.order-notice {
  //   border-top: 1px solid rgba(51, 51, 51, 0.1);
  padding-bottom: 40px;
  .live-order-title {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    line-height: 28px;
    margin-bottom: 40px;
  }
  li {
    font-size: 14px;
    font-weight: 400;
    color: #666;
    line-height: 20px;
    margin-bottom: 10px;
    list-style: decimal;
  }
}
</style>
